<template>
<div>
  <van-cell-group title="新单据">
    <van-cell title="采购申请" is-link size="large" icon="balance-o" to="/mat/valid/new?cat=100" />
    <van-cell title="领用申请" is-link size="large" icon="cart-o" to="/mat/valid/new?cat=200" />
  </van-cell-group>
  <van-cell-group title="处理与查询">
    <van-cell title="我的申请" is-link size="large" icon="contact" to="/mat/valid/lst?for=SUB" />
    <van-cell title="待审记录" is-link size="large" icon="edit" to="/mat/valid/lst?for=VALID&STA=10" v-if="whale.user.validate2(42)"/>
    <van-cell title="全部记录" is-link size="large" icon="search" to="/mat/valid/lst" v-if="whale.user.validate2(44)"/>
  </van-cell-group>
  <van-cell-group title="管理" v-if="whale.user.validate2(43)">
    <van-cell title="配置流程" is-link size="large" icon="setting-o" to="/mat/valid/conf" />
  </van-cell-group>
</div>
</template>
<script>
export default {
  name: "index"
}
</script>
<style scoped></style>